import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import {
  Login,
  Branch,
  Dashboard,
  ResetPassword,
  Otp,
  Forgetpassword,
  Employees,
  AddEmployee,
  AllTransactions,
  EmployeeDetailsUpdated,
  TermsAndPolicies,
  User,
  Employer,
  FullView,
  AddEmployer,
  AddBranch,
  Employee,
  Approval,
  Branches,
  BranchDetails,
  Salaries,
  Transactions,
  CentivCard,
  CentivCardDetails,
  SalaryStatus,
  OnBoardApproval,
  Cashout,
  Establishment,
  EmployeesSideBar,
  Profile,
  ManageUsers,
  ManageEmployees,
  DomesticEmployer,
  FindCardHolder,
  Remittances,
  AuthTwoFactor,
} from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { SideMenu } from "../containers";
import { Navigate } from "react-router";
import { getConstants, getStates, setApprovalsCount, toggleLeftDrawer } from "../store/actions/Common.action";
import { KYC, USER_TYPE } from "../utils/constants";
import { home, employees, multiuser, processSalaries, requestIcon, transactionIcon, userCheck } from "../assets/svg";
import {
  IsExchangeHouse,
  IsExchangeHouseBranch,
  IsExchangeHouseBranchUser,
  IsExchangeHouseUser,
  OpenWhatsApp
} from "../utils/methods";
import { CRefreshModal } from "../components";
import { CLoading } from "../uiComponents";
import { connectionSocket, socket } from "../utils/socket";
import { handleEmitApprovalCounts } from "../pages/approval/helper";
import { flushSync } from "react-dom";
import { logout } from "../store/actions/Auth.action";

function Auth() {
  return (
    <Suspense fallback={<CLoading />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/branch" element={<Branch />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/auth" element={<AuthTwoFactor />} />
        <Route path="/forgetpassword" element={<Forgetpassword />} />
        <Route path="/termsandpolicies" element={<TermsAndPolicies />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}

function Root(props) {
  const stateProps = useSelector(({ common, auth, approval }) => {
    return {
      user: auth.user,
      isOpenLeftDrawer: common.isOpenLeftDrawer,
      clientApprovalCount: common.clientApproval,
      onBoardApprovalCount: common.onBoardApproval,
    };
  });
  const { user, isOpenLeftDrawer, clientApprovalCount, onBoardApprovalCount } = stateProps;

  const getExchangeHouseObj = () => {
    if (IsExchangeHouseBranch() || IsExchangeHouseUser()) {
      return user?.exchangeHouse;
    } else if (IsExchangeHouseBranchUser()) {
      return user?.exchangeHouseBranch?.exchangeHouse;
    } else if (IsExchangeHouse()) {
      return user;
    }
    return {};
  };

  let isCashOutAllowed = getExchangeHouseObj()?.isCashOutAllowed || false;
  let isSalaryProcessAllowed = getExchangeHouseObj()?.viewAccess?.salaryProcess || false;

  const adminRoutes = useMemo(() => [
    {
      key: "DASHBOARD_VIEW",
      title: "Dashboard",
      icon: home,
      path: "/",
      exact: true,
      element: <Dashboard />,
    },
    ...(user?.userType === USER_TYPE?.EXCHANGE_HOUSE_BRANCH || user?.userType === USER_TYPE?.EXCHANGE_HOUSE
      ? [
        {
          key: "USERS_READ",
          title: "Team Members",
          icon: userCheck,
          path: "user",
          exact: true,
          permissions: [],
          element: <User />,
        },
      ] : []),
    ...(isCashOutAllowed ? [{
      key: "CASHOUT_VIEW",
      title: "Send Remittance",
      icon: userCheck,
      path: "cashout",
      exact: true,
      permissions: [],
      element: <Cashout />,
    },
    {
      key: "REMITTANCES_VIEW",
      title: "Remittances",
      icon: userCheck,
      path: "remittances",
      exact: true,
      permissions: [],
      element: <Remittances />,
    }] : []),
    {
      key: "MANAGE_USERS",
      title: "Card Activation / PIN Change",
      icon: requestIcon,
      path: "/manage",
      exact: true,
      element: <ManageUsers />,
    },
    ...(user?.userType === USER_TYPE?.EXCHANGE_HOUSE_BRANCH || user?.userType === USER_TYPE?.EXCHANGE_HOUSE_BRANCH_USER
      ? [
        {
          key: "EMPLOYEES_READ",
          title: "Manage Card Holders",
          icon: employees,
          path: "employees-branch",
          exact: true,
          hideInMenu: false,
          element: <EmployeesSideBar from="sideBar" />,
        },
      ] : []),
    {
      key: "FIND_CARD_HOLDER",
      title: "Find Card Holder",
      icon: requestIcon,
      path: "/card-holders",
      exact: true,
      element: <FindCardHolder />,
    },
    {
      key: "EMPLOYEES_READ",
      title: "Manage Card Holders",
      icon: employees,
      path: "employees",
      exact: true,
      hideInMenu: true,
      element: <Employees />,
    },
    {
      key: "EMPLOYEES_READ",
      title: "Manage Employees",
      icon: employees,
      path: "manageEmployees",
      exact: true,
      hideInMenu: false,
      element: <ManageEmployees />,
    },

    ...(user?.userType === USER_TYPE?.EXCHANGE_HOUSE || user?.userType === USER_TYPE?.EXCHANGE_HOUSE_USER
      ? [
        {
          key: "BRANCHES_READ",
          title: "Branches",
          icon: multiuser,
          path: "branches",
          exact: true,
          element: <Branches />,
        },
        {
          key: "BRANCHES_CREATE",
          title: "Add Branch",
          index: false,
          path: "branches/add",
          exact: true,
          element: <AddBranch />,
          hideInMenu: true,
        },
        {
          key: "BRANCHES_DETAILS_VIEW",
          title: "Branch Details",
          index: false,
          path: "branches/details",
          exact: true,
          element: <BranchDetails />,
          hideInMenu: true,
        },
      ] : []),
    {
      key: "EMPLOYER_VIEW",
      title: "Employers Onboarded",
      icon: employees,
      path: "employer",
      exact: true,
      element: <Employer />,
    },
    {
      key: "EMPLOYER_VIEW",
      title: "Domestic Employer",
      icon: employees,
      path: "domesticEmployer",
      exact: true,
      element: <DomesticEmployer />,
    },
    {
      key: "EMPLOYER_CREATE",
      title: "Add Employer",
      path: "employer/add",
      exact: true,
      index: false,
      hideInMenu: true,
      element: <AddEmployer />,
    },
    {
      key: "EMPLOYER_VIEW",
      title: "Employer",
      path: "employer/view",
      index: false,
      hideInMenu: true,
      element: <FullView />,
    },
    {
      key: "EMPLOYER_VIEW",
      title: "Employee",
      path: "employees",
      // exact: true,
      index: false,
      hideInMenu: true,
      element: <Employee />,
    },
    {
      key: "EMPLOYER_VIEW",
      title: "All Transactions",
      // icon: transactionIcon,
      path: "employee/allTransactions",
      exact: true,
      hideInMenu: true,
      element: <AllTransactions />,
    },
    {
      key: "ESTABLISHMENT_VIEW",
      title: "Establishments",
      path: "establishment",
      icon: transactionIcon,
      exact: true,
      element: <Establishment />,
    },
    {
      key: "",
      title: "Add Employee",
      index: false,
      path: "employee/add",
      exact: true,
      element: <AddEmployee />,
      hideInMenu: true,
    },
    {
      key: "EMPLOYEES_READ",
      title: "Employee Details",
      index: false,
      path: `employee/view`,
      exact: true,
      element: <EmployeeDetailsUpdated />,
      hideInMenu: true,
    },
    {
      key: "APPROVAL_VIEW",
      title: "Approvals",
      icon: requestIcon,
      path: "approval",
      exact: true,
      element: <Approval />,
      permissions: [],
      showTag: true,
      count: clientApprovalCount
    },
    {
      key: "APPROVAL_VIEW",
      title: "On Board Approvals",
      icon: requestIcon,
      path: "onBoardApprovals",
      exact: true,
      element: <OnBoardApproval />,
      permissions: [],
      showTag: true,
      count: onBoardApprovalCount
    },
    {
      key: "SALARY_STATUS",
      title: "Salary Status",
      icon: processSalaries,
      path: "salaryStatus",
      exact: true,
      element: <SalaryStatus />,
      permissions: [],
    },
    {
      key: "TRANSACTIONS_VIEW",
      title: "Transactions",
      icon: transactionIcon,
      path: "transactions",
      exact: true,
      element: <Transactions />,
    },
    ...(user?.employeeTransactionsAccess
      ? [
        {
          key: "TRANSACTIONS_VIEW",
          title: "All Transactions",
          icon: transactionIcon,
          path: "employee/allTransactions",
          exact: true,
          hideInMenu: true,
          element: <AllTransactions />,
        },
      ]
      : []),
    // {
    //   key: "REQUESTS_MAKER",
    //   title: "Requests",
    //   icon: requestIcon,
    //   path: "/requests",
    //   exact: true,
    //   element: <Requests />,
    // },
    // {
    //   key: "CENTIV_CARD_VIEW",
    //   title: "Centiv Card",
    //   icon: processSalaries,

    //   path: "centivCard",
    //   exact: true,
    //   element: <CentivCard />,
    // },
    // {
    //   key: "CENTIV_CARD_VIEW",
    //   title: "Centiv Card Details Page",
    //   icon: processSalaries,
    //   hideInMenu: true,
    //   path: "centivCard/details",
    //   exact: true,
    //   element: <CentivCardDetails />,
    // },
    ...(isSalaryProcessAllowed ? [{
      key: "PROCESSSALARIES_CAN_PROCESS",
      title: "Process Salaries / Fund Transfer Receipt",
      icon: processSalaries,
      path: "processSalaries",
      exact: true,
      element: <Salaries />,
    }] : []),
    {
      key: "ALL",
      title: "Profile",
      path: "profile",
      icon: multiuser,
      exact: true,
      element: <Profile />,
      permissions: [],
    },
  ], [user, clientApprovalCount, onBoardApprovalCount]);

  const [routesState, updateRoutesState] = useState([]);
  const routes = useRoutes(routesState);
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    if (props?.history && props?.history?.location) {
      history(`${props?.history?.location?.pathname}${props?.history?.location?.search}`, { replace: true, state: props?.history?.location?.state });
    }

    dispatch(getStates());
    dispatch(getConstants());
  }, []);

  const approvalCountCheck = () => {
    (async () => {
      if (!socket?.connected) {
        await connectionSocket();
      }
      try {
        socket.on(`approvalCounts`, (data) => {
          flushSync(() =>
            dispatch(setApprovalsCount({
              clientApproval: data?.exchangeHouseApprovalCount || 0,
              onBoardApproval: data?.exchangeHouseOnBoardApprovalCount || 0,
            }))
          );
        });
        socket.on(`approval`, () => {
          handleEmitApprovalCounts()
        });
      } catch (err) {
        console.error('connection error', err);
      }
      handleEmitApprovalCounts();
    })();
  }

  const autoLogoutSocket = () => {
    (async () => {
      if (!socket?.connected) {
        await connectionSocket();
      }
      try {
        socket.on(user.userSession.token, (data) => {
          flushSync(() => {  
            const isAutoLogout = true
            dispatch(logout(isAutoLogout))
          }
          );
        });
      } catch (err) {
        console.error('connection error', err);
      }
    })();
}

  const filterRoute = (array) => {
    return array.filter((obj) => Object.values(user?.roles).find((item) => (obj?.key === "ALL" ? obj : item?.includes(obj?.key))));
  };

  useEffect(() => {
    // if (user && Object.keys(user)?.length) {
    //   if (user?.isClient) {
    //     updateRoutesState(adminRoutes);
    //   } else if (Object.keys(user?.roles)?.length) {
    //     updateRoutesState(filterRoute(adminRoutes));
    //   }
    // }
    updateRoutesState(adminRoutes);
    if (user?.checker || user?.userType === USER_TYPE.EXCHANGE_HOUSE) {
      approvalCountCheck()
    }
    autoLogoutSocket()
  }, [user, clientApprovalCount, onBoardApprovalCount]);

  const toggleLeft = useCallback(() => {
    dispatch(toggleLeftDrawer(!isOpenLeftDrawer));
  }, [dispatch, isOpenLeftDrawer]);

  const toggleClass = useMemo(() => isOpenLeftDrawer ? "left-drawer-open open" : "", [isOpenLeftDrawer]);

  return (
    <div className={`side-menu-with-body ${toggleClass}`}>
      <div className="side-menu-container" onClick={() => toggleLeft()}>
        <SideMenu {...props} routes={routesState} user={user} currentPathName={props && props.location ? props.location.pathname : ""} />
      </div>
      <div className="body-container scroll-y">
        {routesState && routesState?.length ? (
          <Suspense fallback={<CLoading position="relative" />}>
            <Routes>
              {routesState.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              {routesState?.length ? <Route path="*" element={<Navigate to={`${routesState[0]?.path}`} replace />} /> : null}
            </Routes>
          </Suspense>
        ) : null}
        <CRefreshModal />
        {/* {routes && routes?.length ? routes : null} */}
      </div>
      <OpenWhatsApp />
    </div>
  );
}

export { Auth, Root };
